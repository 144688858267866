const baseUrl = window.location.origin;
const API_URL = "https://hub.coderslab.rs/api";

export default {
  API_URL,
  baseUrl,
  googleClientId:
    "860288514084-uqefu4661fjmooo9no5mokl3kjh4sps2.apps.googleusercontent.com",
  googleClientSecret: "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",
  sentryDsn: "https://f92ccdc5b2ddbfd7b067283bdb7002bf@o203382.ingest.sentry.io/4505800245182464",
  selectedLang: "en",
};
